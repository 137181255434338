<template>
  <div class="home">
    <div class="image">
      <van-image
        width="100px"
        style="padding:20px;"
        height="100px"
        fit="contain"
        :src="`https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/${applyState === 3 ? 'account_success' : 'account_processing' }.png`" />
    </div>
    <div class="theme-color">
      <div v-if="submit">已提交</div>
      <div v-else-if="applyState === 1 || applyState === 2">协议审批中</div>
      <div v-else-if="applyState === 4">协议已退回</div>
      <div v-else-if="applyState === 3">协议已通过</div>
      <div class="tip" v-if="submit">
        我们在尽快审批，您可登录查询进展，审批后将及时通知您，请留意！
      </div>
      <div class="tip" v-else-if="applyState === 1 || applyState === 2">
        我们正在认真审批您的资料，审批后我们将短信通知，请留意！
      </div>
      <div class="tip" v-else-if="applyState === 3 || applyState === 4">
        {{approveInfo}}
      </div>
    </div>
    <div
      v-if="applyState === 4"
      style="width: 100%;text-align: center;margin-top: 20px;"
    >
      <van-button type="primary" style="width: 100%;margin: auto;" @click="handleReSign">
        重新签署
      </van-button>
    </div>
    <div
      v-if="applyState === 3"
      style="width: 100%;text-align: center;margin-top: 20px;"
    >
      <van-button style="width: 100%;margin: auto;" @click="toContract">
        查看协议
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Image as VanImage, Button, Toast,
} from 'vant';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import httpApi from '../utils/httpApi';

export default {
  name: 'Search',
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
  },
  methods: {
    async handleReSign() {
      this.$router.push({ path: '/agreement' });
    },
    toContract() {
      this.$router.push('/contract');
    },
  },
  setup() {
    const applyState = ref(-1);
    const approveInfo = ref('');
    const submit = ref(false);
    const router = useRoute();
    onMounted(async () => {
      const store = useStore();
      store.commit('setStepsShow', false);
      if (router.query.type === 'submit') {
        store.commit('setTitleValue', '提交');
        store.commit('setTitleLeft', false);
        submit.value = true;
      } else {
        store.commit('setTitleValue', '查询');
        store.commit('setTitleLeft', false);
        const result = await httpApi.getResult();
        if (!result) return;
        if (result.code === 200) {
          applyState.value = result.data.applyState;
          approveInfo.value = result.data.approveInfo;
        } else {
          Toast(result.msg || '查询失败');
        }
      }
    });
    return {
      applyState, approveInfo, submit,
    };
  },
};
</script>

<style lang="less">
  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home {
    padding-left: @space-0;
    padding-right: @space-0;
  }
  .tip {
    text-align: left;
    margin-top: 20px;
  }
  .container {
    height: 300px;
    overflow-y: auto;
    margin-left: 10px;
    margin-right: 10px;
    text-align: left;
  }
</style>
